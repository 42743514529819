import React from "react"
import Helmet from 'react-helmet'
import SportsBar from '../components/SportsBar'
import FightList from '../components/FightList'
import queryString from 'query-string'


const SportTemplate = ({pageContext}) => {

  const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

  return (
    <>
      <Helmet>
        <title>Fanpowered {pageContext.title} - Vote for the celebrity & athlete match ups you want to see.</title>
      <meta name="description" content="We're on a mission to put the power back in the hands of the fans. Vote on the boxing fights you want to see." />
      </Helmet>
      <SportsBar sport={pageContext.title} />
      <div className="sports-pages">
	      <h4 className="heading">{pageContext.title}</h4>
        <p>{pageContext.description}</p>
	      <FightList path={"/sport/" + pageContext.title.toLowerCase().split(" ").join("-")} sport={pageContext.title} login={parsed.login} />
      </div>
    </>
  )
}

export default SportTemplate